import React from "react";
import { useLayoutEffect } from "react";
import './project.css';

const Project = props => {
    
    
    

    useLayoutEffect(() => {
        const inViewport = (entries, observer) => {
            entries.forEach(entry => {
              entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
            });
        };
          
        const Obs = new IntersectionObserver(inViewport);
        const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
        
        // Attach observer to every [data-inviewport] element:
        const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
        ELs_inViewport.forEach(EL => {
            Obs.observe(EL, obsOptions);
          });
    }, [])

    if (props.width < 768){
        return (
            mobileProject()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabProject()
        )
    }else if (props.width >= 1024){
        return (
            desktopProject()
        )
    }


    function mobileProject(){
        return (
            <div className="w-full mt-10 flex flex-col p-12 mb-20 text-center" data-inviewport='scale-in'>
                <h1 className="text-2xl font-mono text-center text-blue-800 font-bold">Project Name: <span className="text-purple-900">{props.name}</span></h1>
                <p className="mt-14 text-2xl w-4/5 ml-auto mr-auto text-purple-900">Project Functionality</p>
                <p className="w-7/8 ml-auto mr-auto text-xl text-gray-800 mt-6 font-mono">{props.caption}</p>
                <p className="mt-8 text-2xl w-4/5 ml-auto mr-auto text-purple-900">Technology Summary</p>
                <p className="mt-6 w-7/8 ml-auto mr-auto text-lg font-mono text-gray-800">{props.summary}</p>
                {props.link ? <a href={props.link} className="w-1/2 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-green-200 text-center focus dt-btn" >View Live</a> : <></>}
                <a href={props.gitlink} className="w-1/2 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-gray-200 text-center focus dt-btn" >View on GitHub</a>
            </div>
        )
    }
    function tabProject(){
        return (
            <div className="w-3/4 bg-gray-200 mt-20 flex flex-col p-12 mb-20" id="dt">
                <h1 className="text-2xl font-mono text-center text-blue-800 font-bold">Project Name: <span className="text-purple-900">{props.name}</span></h1>
                <p className="mt-14 text-2xl w-2/3 ml-auto mr-auto text-purple-900">Project Functionality</p>
                <p className="w-2/3 ml-auto mr-auto text-xl text-gray-800 mt-6 font-mono">{props.caption}</p>
                <p className="mt-8 text-2xl w-2/3 ml-auto mr-auto text-purple-900">Technology Summary</p>
                <p className="mt-6 w-2/3 ml-auto mr-auto text-lg font-mono text-gray-800">{props.summary}</p>
                {props.link ? <a href={props.link} className="w-1/3 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-green-200 text-center focus dt-btn" >View Live</a> : <></>}
                <a href={props.gitlink} className="w-1/3 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-blue-200 text-center focus dt-btn" >View on GitHub</a>
            </div>
        )
    }
    function desktopProject(){
        return (
            <div className="w-1/2 bg-gray-200 mt-20 flex flex-col p-12 mb-20" id="dt">
                <h1 className="text-2xl font-mono text-center text-blue-800 font-bold">Project Name: <span className="text-purple-900">{props.name}</span></h1>
                <p className="mt-14 text-2xl w-2/3 ml-auto mr-auto text-purple-900">Project Functionality</p>
                <p className="w-2/3 ml-auto mr-auto text-xl text-gray-800 mt-6 font-mono">{props.caption}</p>
                <p className="mt-8 text-2xl w-2/3 ml-auto mr-auto text-purple-900">Technology Summary</p>
                <p className="mt-6 w-2/3 ml-auto mr-auto text-lg font-mono text-gray-800">{props.summary}</p>
                {props.link ? <a href={props.link} className="w-1/4 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-green-200 text-center focus hover:text-white hover:bg-purple-500 dt-btn" >View Live</a> : <></>}
                <a href={props.gitlink} className="w-1/4 rounded-lg font-serif ml-auto mr-auto mt-10 text-xl text-blue-900 bg-blue-200 text-center focus hover:text-white hover:bg-blue-500 dt-btn" >View on GitHub</a>
            </div>
        )
    }

    
}

export default Project;