import React from "react";
import '../../index.css';
import './Footer.css';
import { useEffect } from "react";

const Footer = (props) => {
    const twitter = 'https://www.kadewebsolutions.com/twitter.png';
    const github = 'https://www.kadewebsolutions.com/github.png';

    if (props.width < 768){
        return (
            mobileFoot()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabletFoot()
        )
    }else if (props.width >= 1024){
        return (
            desktopFoot()
        )
    }
    

    function mobileFoot(){
        return (
            <footer className="bottom-0 w-full bg-transparent bg-blue-700 p-3 flex flex-col place-items-center "> 
                <div className="flex place-content-evenly mt-1 w-full">
                    <a href="https://www.linkedin.com/in/christopher-kade-b9b2151a5/" className="soc-link text-blue-400 not-italic font-bold text-2xl rounded-full h-10 w-10 text-center leading-8">in</a>
                    <a href="https://twitter.com/Christhedev__" className="soc-link h-10 w-10 bg-red-600 rounded-full flex justify-center"><img src={twitter} className="w-5/6" alt="twitter icon" /></a>
                    <a href="https://www.github.com/ckade15" className="git-link h-10 w-10 bg-blue-400 rounded-full flex justify-center"><img src={github} alt="github icon" className="w-full h-full"/></a>
                </div>
                <p className="text-md font-bold text-green-200 font-mono p-1 mt-2">&copy; Kade Web Solutions  {(new Date().getFullYear())}</p>
                <p className="font-mono text-sm font-bold text-blue-200 mb-1">Made by Chris Kade</p>
            </footer>
        )
    }
    function tabletFoot(){
        return (
            <footer className="bottom-0 w-full bg-transparent bg-blue-700 p-5 flex flex-col place-items-center"> 
                <div className="flex place-content-evenly mt-1 mb-2 w-full">
                    <a href="https://www.linkedin.com/in/christopher-kade-b9b2151a5/" className="soc-link text-blue-400 not-italic font-bold text-2xl rounded-full h-10 w-10 text-center leading-8">in</a>
                    <a href="https://twitter.com/Christhedev__" className="soc-link h-10 w-10 bg-red-600 rounded-full flex justify-center"><img src={twitter} className="w-5/6" alt="twitter icon" /></a>
                    <a href="https://www.github.com/ckade15" className="git-link h-10 w-10 bg-blue-400 rounded-full flex justify-center"><img src={github} alt="github icon" className="w-full h-full"/></a>
                </div>
                <p className="text-md font-bold text-green-200 font-mono p-1 mt-2">&copy; Kade Web Solutions  {(new Date().getFullYear())}</p>
                <p className="font-mono text-sm font-bold text-blue-200 mb-1">Made by Chris Kade</p>
            </footer>
        )
    }
    function desktopFoot(){
        return (
            <footer className="bottom-0 w-full bg-transparent bg-blue-700 p-10 flex justify-evenly text-xl">
                <div className="flex flex-col w-1/2 text-xl text-center">
                    <p className="text-md font-bold text-green-200 font-mono p-1 mt-2">&copy; Kade Web Solutions  {(new Date().getFullYear())}</p>
                    <p className="font-mono text-sm font-bold text-blue-200 mb-1">Made by Chris Kade</p>
                </div> 
                <div className="flex place-content-evenly mt-1 w-1/3 ">
                    <a href="https://www.linkedin.com/in/christopher-kade-b9b2151a5/" className="soc-link text-blue-400 not-italic font-bold text-2xl rounded-full h-10 w-10 text-center leading-8">in</a>
                    <a href="https://twitter.com/Christhedev__" className="soc-link h-10 w-10 bg-red-600 rounded-full flex justify-center"><img src={twitter} className="w-5/6" alt="twitter icon" /></a>
                    <a href="https://www.github.com/ckade15" className="git-link h-10 w-10 bg-blue-400 rounded-full flex justify-center"><img src={github} alt="github icon" className="w-full h-full"/></a>
                </div>
            </footer>
        )
    }
}

export default Footer;