import React, { useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import AboutContent from "../components/AboutContent/AboutContent";
import './stylesheets/AboutPage.css';

const AboutPage = (props) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(window.innerWidth);
        function handleScreenSize() {
            setWidth(window.innerWidth);
            console.log('Screen size width changed to: ' + window.innerWidth);
        }
        window.addEventListener('resize', handleScreenSize);
    }, []);
    
    return (
        <div id="main-bg">
            <Navbar width={width} />
            {width <= 1024 ? <div className="head-padding top-0"></div> : <div className="head-padding-dsk top-0"></div> }
            <AboutContent width={width}/>
            <Footer width={width}/>
        </div>
    );
}

export default AboutPage;