import React from "react";
import { Link } from "react-router-dom";
import "./AppContent.css";
import { useLayoutEffect } from "react";

const AppContent = (props) => {
    const personal = 'https://kadewebsolutions.com/personal.JPEG';
    const chris = 'https://kadewebsolutions.com/chris.png';

    useLayoutEffect(() => {
        
    }, [])
    if (props.width < 768){
        return (
            mobileAppContent()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabletAppContent()
        )
    }else if (props.width >= 1024){
        return (
            desktopAppContent()
        )
    }
    function mobileAppContent(){
        return (
            <section id="mobile-app-content" className=" w-full min-h-screen">
                <div className="w-full flex flex-col place-items-center pt-6">
                    <div className="w-2/3 mb-14" >
                        <h1 id="front-head" className="text-green-200 text-4xl font-bold mt-4" >Freelance Web <br />Developer</h1>
                        <p className="cont-head font-mono text-white mt-5">I design and code scalable frontend websites optimized for your business needs.</p>
                    </div>
                    <div className="w-1/2 border-2 border-purple-200 rounded-full flex-wrap personal-img">
                        <img src={personal} alt="Chris Kade Headshot" className="rounded-full"/>
                    </div>
                    <div className="w-full mt-10 sec-2">
                        <img id="chris-bitmoji" src={chris} alt="Bitmoji Chris" className="h-24 ml-8 "/>
                    </div>
                    <div className="w-full bg-purple-200 p-3 pb-28 block" >
                        <div  className="w-fit h-fit">
                            <h2 className="intro text-2xl w-4/5 text-center ml-auto mr-auto text-blue-600 mt-6">Hi, I'm Chris. Let me introduce myself.</h2>
                            <p className="font-mono text-md w-3/4 ml-auto mr-auto mt-3 text-center">I'm a full-stack web developer with a passion for creating beautiful, functional websites. I'm currently working as a freelance web developer, but I'm always looking for new opportunities to learn and grow.</p>
                        </div>
                    </div>
                    <div className="bg-gray-200 w-4/5 about-section absolute rounded-lg p-5 flex flex-col justify-content-center place-items-center " >
                        <div className="flex flex-col place-items-center" >
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M20.97,7.27c0.39-0.39,0.39-1.02,0-1.41l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0l-4.49,4.49L8.35,3.63 c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9c-0.78,0.78-0.78,2.05,0,2.83l3.89,3.89L3,16.76V21h2.24l4.52-4.52l3.89,3.89 c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9c0.78-0.78,0.78-2.05,0-2.83l-3.89-3.89L20.97,7.27z M5.04,6.94l1.89-1.9c0,0,0,0,0,0 l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19l1.2,1.2l-1.9,1.9L5.04,6.94z M16.27,14.38l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27 l-1.9,1.9l-3.89-3.89l1.9-1.9L16.27,14.38z M6.41,19H5v-1.41l9.61-9.61l1.3,1.3l0.11,0.11L6.41,19z M16.02,6.56l1.41-1.41 l1.41,1.41l-1.41,1.41L16.02,6.56z"/></g></g></svg>
                            <p className="text-lg mt-2 text-blue-600">Design</p>
                            <p className="font-mono text-sm text-center mt-2">I design simple but effective web applications for all screen sizes. Figma is the main tool I use in the design process. </p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>
                            <p className="text-lg mt-2 text-blue-600">Development</p>
                            <p className="font-mono text-sm text-center mt-2">I code fullstack web applications from scratch using a variety of technologies, frameworks, and libraries. Programming languages I use include 
                            HTML, CSS, Javascript, Java, and Python. The libraries or frameworks I use are ReactJS, TailwindCSS, and NodeJS.</p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94s3.08 1.32 3.08 2.94c0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/></svg>
                            <p className="text-lg mt-2 text-blue-600">Mission</p>
                            <p className="font-mono text-sm text-center mt-2">It's no secret that in todays day and age, a solid website is almost a necessity to run a successful business. It is one of the greatest forms of advertising for any type of business because it is accessible to everyone.
                            Let me take care of the design and development and we'll work together to deliver a website that best fits your companies needs.</p>
                        </div>
                    </div>
                    <div  className="blue-bg w-full bg-blue-300 ">
                        
                    </div>
                    <div className="purple-bg w-full bg-purple-200  p-3 flex justify-center flex-col" >
                        
                        <p className="text-center text-xl font-bold ml-auto mr-auto text-blue-600 " >Bring your business to the next level</p>
                        <p className="font-mono text-md text-center mt-2 text-purple-600" >Get a quote today!</p>
                        <Link to="/contact" id="mobile-button" className="opacity-90" >Contact me</Link>
                    </div>
                </div>
            </section>
        )
    }
    function tabletAppContent(){
        return (
            <section id="mobile-app-content" className=" w-full min-h-screen">
                <div className="w-full flex flex-col place-items-center pt-6">
                    <div className="w-2/3 mb-14">
                        <h1 id="front-head" className="text-green-200 text-4xl font-bold mt-4">Freelance Web <br />Developer</h1>
                        <p className="cont-head font-mono text-white mt-5">I design and code scalable frontend websites optimized for your business needs.</p>
                    </div>
                    <div className="w-1/2 border-2 border-purple-200 rounded-full flex-wrap personal-img">
                        <img src={personal} alt="Chris Kade Headshot" className="rounded-full"/>
                    </div>
                    <div className="w-full mt-10 sec-2">
                        <img id="chris-bitmoji" src={chris} alt="Bitmoji Chris" className="h-24 ml-8 "/>
                    </div>
                    <div className="w-full bg-purple-200 p-40 block">
                        <h2 className="intro text-2xl w-4/5 text-center ml-auto mr-auto text-blue-600">Hi, I'm Chris. Let me introduce myself.</h2>
                        <p className="font-mono text-md w-3/4 ml-auto mr-auto mt-6 text-center">I'm a full-stack web developer with a passion for creating beautiful, functional websites. I'm currently working as a freelance web developer, but I'm always looking for new opportunities to learn and grow.</p>
                    </div>
                    <div className=" bg-gray-200 w-4/5 rounded-lg p-20 absolute about-tablet flex flex-col justify-content-center place-items-center  ">
                        <div className="flex flex-col place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M20.97,7.27c0.39-0.39,0.39-1.02,0-1.41l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0l-4.49,4.49L8.35,3.63 c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9c-0.78,0.78-0.78,2.05,0,2.83l3.89,3.89L3,16.76V21h2.24l4.52-4.52l3.89,3.89 c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9c0.78-0.78,0.78-2.05,0-2.83l-3.89-3.89L20.97,7.27z M5.04,6.94l1.89-1.9c0,0,0,0,0,0 l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19l1.2,1.2l-1.9,1.9L5.04,6.94z M16.27,14.38l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27 l-1.9,1.9l-3.89-3.89l1.9-1.9L16.27,14.38z M6.41,19H5v-1.41l9.61-9.61l1.3,1.3l0.11,0.11L6.41,19z M16.02,6.56l1.41-1.41 l1.41,1.41l-1.41,1.41L16.02,6.56z"/></g></g></svg>
                            <p className="text-lg mt-2 text-blue-600">Design</p>
                            <p className="font-mono text-sm text-center mt-2">I design simple but effective web applications for all screen sizes. Figma is the main tool I use in the design process. </p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>
                            <p className="text-lg mt-2 text-blue-600">Development</p>
                            <p className="font-mono text-sm text-center mt-2">I code fullstack web applications from scratch using a variety of technologies, frameworks, and libraries. Programming languages I use include 
                            HTML, CSS, Javascript, Java, and Python. The libraries or frameworks I use are ReactJS, TailwindCSS, and NodeJS.</p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94s3.08 1.32 3.08 2.94c0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/></svg>
                            <p className="text-lg mt-2 text-blue-600">Mission</p>
                            <p className="font-mono text-sm text-center mt-2">It's no secret that in todays day and age, a solid website is almost a necessity to run a successful business. It is one of the greatest forms of advertising for any type of business because it is accessible to everyone.
                            Let me take care of the design and development and we'll work together to deliver a website that best fits your companies needs.</p>
                        </div>
                    </div>
                    <div  className="blue-tablet w-full bg-blue-300 ">
                        
                    </div>
                    <div className="purple-bg-tab w-full bg-purple-200  p-20 flex justify-center flex-col">
                        <p className="text-center text-xl font-bold ml-auto mr-auto text-blue-600 ">Bring your business to the next level</p>
                        <p className="font-mono text-md text-center mt-2 text-purple-600">Get a quote today!</p>
                        <Link to="/contact" id="mobile-button" className="opacity-90">Contact me</Link>
                    </div>
                </div>
            </section>
        )
    }
    function desktopAppContent (){
        return (
            
                <main className="w-full flex flex-col place-items-center pt-6">
                    <div className="w-5/6 mb-14 flex h-full justify-between">
                        <div className="flex flex-col place-items-center">
                            <h1 id="front-head" className="text-green-200 text-6xl font-bold mt-4">Freelance Web <br />Developer</h1>
                            <div className="w-1/2 border-2 border-purple-200 rounded-full flex-wrap personal-img mt-20">
                                <img src={personal} alt="Chris Kade Headshot" className="rounded-full"/>
                            </div>
                        </div>
                        <div className="w-3/4 ml-10 bg-purple-200 block h-3/4 mt-44 p-10 rounded-md border-2 border-gray-500 border-solid">
                            <h2 className="intro text-3xl w-4/5 text-center ml-auto mr-auto text-blue-600 mt-6">Hi, I'm Chris. Let me introduce myself.</h2>
                            <hr className="ml-auto mr-auto"/>
                            <p className="font-mono text-xl w-3/4 ml-auto mr-auto mt-9 text-center">I'm a full-stack web developer with a passion for creating beautiful, functional websites. I'm currently working as a freelance web developer, but I'm always looking for new opportunities to learn and grow.</p>
                        </div>
                        {/* <p className="cont-head font-mono text-lg text-white mt-5">I design and code scalable frontend websites optimized for your business needs.</p>*/}
                    </div>
                    
                    <div className="w-full mt-10 sec-2-dsk">
                        <img id="chris-bitmoji" src={chris} alt="Bitmoji Chris" className="h-40 ml-8 "/>
                    </div>
                    
                    <div className="bg-gray-200 w-1/2 about-desktop absolute rounded-lg p-10 flex flex-col justify-content-center place-items-center ">
                        <div className="flex flex-col place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M20.97,7.27c0.39-0.39,0.39-1.02,0-1.41l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0l-4.49,4.49L8.35,3.63 c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9c-0.78,0.78-0.78,2.05,0,2.83l3.89,3.89L3,16.76V21h2.24l4.52-4.52l3.89,3.89 c0.95,0.95,2.23,0.6,2.83,0l1.9-1.9c0.78-0.78,0.78-2.05,0-2.83l-3.89-3.89L20.97,7.27z M5.04,6.94l1.89-1.9c0,0,0,0,0,0 l1.27,1.27L7.02,7.5l1.41,1.41l1.19-1.19l1.2,1.2l-1.9,1.9L5.04,6.94z M16.27,14.38l-1.19,1.19l1.41,1.41l1.19-1.19l1.27,1.27 l-1.9,1.9l-3.89-3.89l1.9-1.9L16.27,14.38z M6.41,19H5v-1.41l9.61-9.61l1.3,1.3l0.11,0.11L6.41,19z M16.02,6.56l1.41-1.41 l1.41,1.41l-1.41,1.41L16.02,6.56z"/></g></g></svg>
                            <p className="text-xl mt-2 text-blue-600">Design</p>
                            <p className="font-mono text-md text-center mt-2">I design simple but effective web applications for all screen sizes. Figma is the main tool I use in the design process. </p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>
                            <p className="text-xl mt-2 text-blue-600">Development</p>
                            <p className="font-mono text-md text-center mt-2">I code fullstack web applications from scratch using a variety of technologies, frameworks, and libraries. Programming languages I use include 
                            HTML, CSS, Javascript, Java, and Python. The libraries or frameworks I use are ReactJS, TailwindCSS, and NodeJS.</p>
                            <hr />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#161ec4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39s-4.66 1.97-4.66 4.39c0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94s3.08 1.32 3.08 2.94c0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/></svg>
                            <p className="text-xl mt-2 text-blue-600">Mission</p>
                            <p className="font-mono text-md text-center mt-2">It's no secret that in todays day and age, a solid website is almost a necessity to run a successful business. It is one of the greatest forms of advertising for any type of business because it is accessible to everyone.
                            Let me take care of the design and development and we'll work together to deliver a website that best fits your companies needs.</p>
                        </div>
                    </div>
                    <div  className="blue-bg-dsk w-full bg-blue-300 ">
                        
                    </div>
                    <div className="purple-bg-dsk w-full bg-purple-200 flex justify-center flex-col">
                        <p className="text-center text-xl font-bold ml-auto mr-auto text-blue-600 ">Bring your business to the next level</p>
                        <p className="font-mono text-lg text-center mt-4 text-purple-600 mb-4">Get a free quote today!</p>
                        <Link to="/contact" id="mobile-button" className="opacity-90">Contact me</Link>
                    </div>
                </main>
        )
    }
}

export default AppContent;