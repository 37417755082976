import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import './stylesheets/FourOhFour.css';

const FourOhFour = (props) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
    document.title = '404';
    function handleScreenSize() {
      setWidth(window.innerWidth);
      console.log('Screen size width changed to: ' + window.innerWidth);
    }
    window.addEventListener('resize', handleScreenSize);
  }, []);

  
  function screen1() {
    return (
        <div id="main-bg">
            <Navbar width={width}/>
            {width <= 1024 ? <div className="head-padding top-0"></div> : <div className="head-padding-dsk top-0"></div>  }
            <section className="section-1 w-full h-full bg-purple-200 flex place-items-center flex-col">
                <p className="text-4xl four-1 error w-full text-center">404 Error</p>
                <p className="w-1/2 text-center mt-14 font-mono text-sm">Uh oh! It appears that the page you are searching for does not exist.</p>
                <Link to="/" className="mt-10" id="link-home-1">Go Home</Link>
            </section>
            <Footer width={width}/>
        </div>
    );
  }
  return(
    screen1()
  )
    
}


export default FourOhFour;