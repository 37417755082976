import './Navbar.css';
import React, { useState, useEffect } from 'react';
import { MenuItems } from './Menuitem';
import { Link} from 'react-router-dom';


const Navbar = (props) => {
    const [menu, setMenu] = useState("hidden");
    
    function handleRedirect(){
        setMenu("hidden");
        document.getElementById("menu_checkbox").checked = false;
        window.scrollTo(0, 0);
    }
    
    function mobileNavbar(){
        return (
            <nav className="NavbarItems flex flex-col w-full ">
                <header className="flex justify-between w-full font-bold flex-wrap pt-2 place-items-center p-2 bg-gray-200" >
                    <h1 className={`nav-header text-4xl text-blue-600 font-bold ml-4 w-5/6`}>Kade Web Solutions</h1>
                    <input type="checkbox" id="menu_checkbox" onClick={handleMenu} />
                    <label htmlFor="menu_checkbox" >
                    <div></div>
                    <div></div>
                    <div></div>
                    </label>
                </header>    
                <ul className={`w-full min-h-screen font-bold bg-purple-200 border-box border-2  flex flex-col text-center font-mono nav-menu ${menu} gap-10`} >        
                    {MenuItems.map((item, index) => {
                        return (<Link key={index} to={item.url} onClick={handleRedirect && handleAni} className={`mt-10 font-bold  text-blue-400 text-2xl hover:text-purple-400 p-3 ${item.cName}`}>{item.title}</Link>)
                    })}
                </ul>
            </nav>
        )
    }
    function tabletNav(){
        return (
            <nav className="NavbarItems flex flex-col w-full opacity-90">
                <header className="flex justify-between w-full font-bold flex-wrap pt-2 place-items-center p-2 bg-white" >
                    <h1 className={`nav-header text-4xl text-blue-600 font-bold ml-4 w-5/6`}>Kade Web Solutions</h1>
                    <input type="checkbox" id="menu_checkbox" onClick={handleMenu}/>
                    <label htmlFor="menu_checkbox" >
                    <div></div>
                    <div></div>
                    <div></div>
                    </label>
                </header>    
                <ul className={`w-full font-bold bg-purple-200 border-box border-2 border-purple-400 flex flex-col text-center font-mono nav-menu ${menu}`} >        
                    {MenuItems.map((item, index) => {
                        return (<Link key={index} to={item.url} onClick={handleRedirect} className={`nav-link font-bold bg-white text-blue-400 text-lg border-purple-400 hover:text-purple-400 p-3 ${item.cName}`}>{item.title}</Link>)
                    })}
                </ul>
            </nav>
        )
    }
    function desktopNav(){
        return (
            <nav className=" absolute flex flex-col w-full opacity-90">
                <header className="flex justify-between w-full font-bold flex-wrap pt-2 place-items-center p-2 bg-white" >
                    <h1 className={`nav-header text-4xl text-blue-600 font-bold ml-4`}>Kade Web Solutions</h1>
                    <ul className={`font-bold flex text-center justify-evenly font-mono w-1/2 text-2xl`} >        
                        {MenuItems.map((item, index) => {
                            return (<Link key={index} to={item.url} onClick={handleRedirect} className={`font-bold bg-white text-blue-400 text-2xl hover:text-purple-400 p-3 `}>{item.title}</Link>)
                        })}
                    </ul>
                </header>         
            </nav>
        )
    }
    function handleAni(){
        const nav = document.querySelector("header > a");
        nav.classList.add("nav-link")
    }

    function handleMenu(){
        if (menu === "hidden"){
            setMenu("visible");
        }else{
            setMenu("hidden");
        }
    }
    if (props.width < 768){
        return (
            mobileNavbar()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabletNav()
        )
    }else if (props.width >= 1024){
        return (
            desktopNav()
        )
    }
}

export default Navbar;