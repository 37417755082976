import React, { useState, useEffect} from "react";
import Navbar from "../components/Navbar/Navbar";
import '../index.css';
import '../components/Navbar/Navbar.css';
import Footer from "../components/Footer/Footer";
import ThankYouContent from "../components/ThankYouContent/ThankYouContent";
//const Footer = React.lazy(() => import('../components/Footer/Footer'));

const ThankYou = (props) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(window.innerWidth, []);
        document.title = 'Contact - Thank You';
        function handleScreenSize() {
            setWidth(window.innerWidth);
            console.log('Screen size width changed to: ' + window.innerWidth);
        }
        window.addEventListener('resize', handleScreenSize);
    }, []);
    return (
        <div id="main-bg">
            <Navbar width={width}/>
            {width <= 1024 ? <div className="head-padding top-0"></div> : <div className="head-padding-dsk top-0"></div>  }
            <ThankYouContent width={width} />
            <Footer width={width}/>
        </div>
    )
}

export default ThankYou;