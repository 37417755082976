export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links',  
    },
    {
        title: 'Portfolio',
        url: '/portfolio',
        cName: 'nav-links',  
    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links',  
    },
    {
        title: 'Contact',
        url: '/contact',
        cName: 'nav-links',  
    }
]