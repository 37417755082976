import React from 'react';

function ThankYouContent(props) {
    if (props.width < 768){
        return (
            mobileContent()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabContent()
        )
    }else if (props.width >= 1024){
        return (
            desktopContent()
        )
    }
    function mobileContent() {
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <h5 className='text-2xl text-purple-800 mt-40'>Thank you for reaching out!</h5>
                    <p className='text-center font-mono text-sm mt-8 w-3/4'>I usually respond to emails within 24-48 hours.</p>
                </section>
            </main>
        )
    }
    function tabContent(){
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <h5 className='text-2xl text-purple-800 mt-40'>Thank you for reaching out!</h5>
                    <p className='text-center font-mono text-sm mt-8 w-3/4'>I usually respond to emails within 24-48 hours.</p>
                </section>
            </main>
        )
    }
    function desktopContent(){
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <h5 className='text-4xl text-purple-800 mt-40'>Thank you for reaching out!</h5>
                    <p className='text-center font-mono text-lg mt-8 w-3/4'>I usually respond to emails within 24-48 hours.</p>
                </section>
            </main>
        )
    }
}

export default ThankYouContent;
