import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import ContactContent from "../components/ContactContent/ContactContent";
import Footer from "../components/Footer/Footer";

const ContactPage = (props) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(window.innerWidth);
        document.title = 'Contact';
        function handleScreenSize() {
            setWidth(window.innerWidth);
            console.log('Screen size width changed to: ' + window.innerWidth);
        }
        window.addEventListener('resize', handleScreenSize);
    }, []);
    function mobileContact(){
        return (
            <div className="w-full min-h-screen">
                <Navbar width={width} />
                {width <= 1024 ? <div className="head-padding top-0"></div> : <div className="head-padding-dsk top-0"></div>  }
                <ContactContent width={width} />
                <Footer width={width} />
            </div>
        );
    }
    return (mobileContact());
}


export default ContactPage;