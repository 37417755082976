import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import './index.css';
import App from './pages/App';
import PortfolioPage from './pages/PortfolioPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import FourOhFour from './pages/FourOhFour';
import reportWebVitals from './reportWebVitals';
import ThankYou from './pages/ThankYou';
import Navbar from './components/Navbar/Navbar';

/* 
    Screen Sizing:
    - Mobile: < 600px
    - Tablet: 600px - 900px
    - Desktop: 900px - 1200px
*/

ReactDOM.render(
  <React.StrictMode>
    
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/contacted" element={<ThankYou/>} />
          <Route path="*" element={<FourOhFour />} ></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(console.log);


