import React from 'react';
import './ContactContent.css';
import {send} from '@emailjs/browser';
import{ init } from '@emailjs/browser';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { useLayoutEffect } from 'react';

function ContactContent(props) {
    let navigate = useNavigate();
    const redirect = () => {
        navigate('/contacted');
    }

    useLayoutEffect(() => {
        
    }, [])
    const [toSend, setToSend] = useState({
        name: '',
        email: '',
        to_name: 'chriskade1999@gmail.com',
        message: ''
    });

    const onSubmit = (e) => {
        init('RedaFidK0LexXNrTh');
        e.preventDefault();
        if (toSend.name === '') {
            document.getElementById('error').innerHTML = '';
            document.getElementById('error').innerHTML += 'Please enter your name<br>';
        }
        
        if (toSend.email === ''){
            document.getElementById('error').innerHTML += 'Please enter your email<br>';
        }
        if (toSend.message === ''){
            document.getElementById('error').innerHTML += 'Please enter a message<br>';
        }
        if (document.getElementById('error').innerHTML === ''){
            send(
                'chriskade1999',
                'template_o0j2puj',
                toSend,
            ).then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                redirect();
            }).catch((error) => {console.log(error)});
        }

    }
    const handleChange = (e) =>{
        setToSend({...toSend, [e.target.name]: e.target.value});
        console.log(toSend);
    }

    function mobileContact(){
            return (
                <section className='w-full bg-purple-200 flex justify-center flex-col place-items-center pb-10' >
                        <h3 id='mobile-con' className='mt-8' >Contact Me</h3>
                        <p className='font-mono mt-20 w-2/3 text-center' >Let me bring your business to the web. </p>
                        <div className='flex mt-40 w-full flex-col place-items-center'>
                            <div className='flex flex-col place-content-center place-items-center'>
                                <svg  className='min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                                <p  className=' text-center text-blue-600 w-5/6'>Located in Lake Orion, Michigan but I can work remotely</p>
                            </div>
                            <br />
                            <div className='flex flex-col mt-8 place-content-center place-items-center'>
                                <svg  className=' min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
                                <p  className='text-blue-600 text-center'>chriskade1999@gmail.com</p>
                            </div>
                        </div>
                        < hr id="hr-mob-full"/>
                        <form action='mailto:chriskade1999@gmail.com' method='post' className='p-4 w-full flex flex-col place-items-center mt-20' onSubmit={onSubmit}>
                            <input  onChange={handleChange} type='text' id='name' name='name' placeholder='Full Name' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl ' />
                            <input  onChange={handleChange} type='text' id='email' name='email' placeholder='Email' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8' />
                            <textarea  onChange={handleChange} name='message' id='message' placeholder='Enter your message' className='w-full h-44 p-2 rounded-lg bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8'></textarea>
                            <p id='error' className='pt-10 text-xl text-red-600'></p>
                            <button  type='submit' className='mob-but rounded-full mt-10'>Send Message</button>
                        </form>
                </section>
            );
        }
        function tabletContact(){
            return (
                <section className='w-full p-4 bg-purple-200'>
                    <div className='w-full flex justify-center flex-col place-items-center p-8'>
                        <h3 id='mobile-con' >Contact Me</h3>
                        <p className='font-mono mt-20 w-2/3 text-center'>Let me bring your business to the web. </p>
                        <div className='flex justify-center mt-40 w-full flex-col place-items-center'>
                            <div className='flex justify-between w-2/3 ml-auto mr-auto'>
                                <svg className='mr-2 min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                                <p className='text-center text-blue-600'>Located in Lake Orion, Michigan but I can work remotely</p>
                            </div>
                            <br />
                            <div className='flex justify-between w-2/3 ml-auto mr-auto mt-8'>
                                <svg className='mr-4 min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
                                <p className='text-blue-600 mr-24'>chriskade1999@gmail.com</p>
                            </div>
                        </div>
                        < hr id="hr-mob-full"/>
                        <form action='mailto:chriskade1999@gmail.com' method='post' className='w-full flex flex-col place-items-center mt-20' onSubmit={onSubmit}>
                            <input onChange={handleChange} type='text' name='name' placeholder='Full Name' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl ' />
                            <input onChange={handleChange} type='text' name='email' placeholder='Email' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8' />
                            <textarea onChange={handleChange} name='message' placeholder='Enter your message' className='w-full h-44 p-2 rounded-lg bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8'></textarea>
                            <p id='error' className='pt-10 text-2xl text-red-600'></p>
                            <button type='submit' className='mob-but rounded-full mt-10'>Send Message</button>
                        </form>
                    </div>
                </section>
            )
        }
        function desktopContact(){
            return (
                <section className='w-full p-4 bg-purple-200'>
                    <div className='w-full flex justify-center flex-col place-items-center p-8'>
                        <h3 id='mobile-con' >Contact Me</h3>
                        <p className='font-mono mt-20 w-2/3 text-center text-xl'>Let me bring your business to the web. </p>
                        <div className='flex justify-center mt-40 w-full flex-col place-items-center'>
                            <div className='flex justify-between w-1/3 ml-auto mr-auto'>
                                <svg className='mr-2 min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                                <p className='text-center text-blue-600 text-xl'>Located in Lake Orion, Michigan but I can work remotely</p>
                            </div>
                            <br />
                            <div className='flex justify-between w-1/3 ml-auto mr-auto mt-8'>
                                <svg className='mr-4 min-w-max' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#0b00dc"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
                                <p className='text-blue-600 mr-24 text-xl'>chriskade1999@gmail.com</p>
                            </div>
                        </div>
                        < hr id="hr-mob-dsk"/>
                        <form action='mailto:chriskade1999@gmail.com' method='post' className='w-2/3 flex flex-col place-items-center mt-20' onSubmit={onSubmit}>
                            <input onChange={handleChange} type='text' name='name' placeholder='Full Name' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl ' />
                            <input onChange={handleChange} type='text' name='email' placeholder='Email' className='w-full h-10 p-4 rounded-full bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8' />
                            <textarea onChange={handleChange} name='message' placeholder='Enter your message' className='w-full h-44 p-2 rounded-lg bg-blue-200 placeholder-purple-600 text-purple-600 text-xl mt-8'></textarea>
                            <p id='error' className='pt-10 text-2xl text-red-600'></p>
                            <button type='submit' className='dsk-but rounded-full mt-10'>Send Message</button>
                        </form>
                    </div>
                </section>
            )
    }


    if (props.width < 768){
        return (
            mobileContact()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabletContact()
        )
    }else if (props.width >= 1024){
        return (
            desktopContact()
        )
    }
}
    

export default ContactContent;
