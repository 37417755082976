import React from 'react';
import Project from '../Project/Project';
import '../Project/project.css';

function PortfolioContent(props) {
    if (props.width < 768){
        return (
            mobilePortContent()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabPortContent()
        )
    }else if (props.width >= 1024){
        return (
            desktopPortContent()
        )
    }
    function mobilePortContent() {
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <p className='text-4xl font-mono mt-20 font-bold text-blue-800 ' id='mob-h'>My Work</p>
                    <Project width={props.width} name="FIDE Chess Ranking Website" 
                        caption={"Full-stack web application that allows you to view the top 100 chess players in the standard, rapid, and blitz time control."} 
                        summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, and MongoDB for the backend. I wrote bots in Python to webscrape the rankings and upload them to the database through the API." 
                        link="https://fiderankings.kadewebsolutions.com" 
                        gitlink="https://www.github.com/ckade15/chess"
                        />
                    <Project width={props.width} name="Notes Web Application" 
                        caption={"Full-stack web application that allows you to create, view, and delete notes. Features a login system with email authentication using nodemailer upon account creation."}
                        summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, jsonwebtoken, nodemailer, and MongoDB for the backend. An email is sent to the user after an account is created with a link to confirm your account. Once confirmed, the user can add, update, or delete their personal notes."
                        gitlink="https://github.com/ckade15/notes-app" />
                    <Project width={props.width} name="Easy-Polls Website" 
                        caption="Full-stack web application that allows users to create, manage, and view polls in real-time. Features a full login system along with authentication. When a poll is created that poll will have a joinable socket that those who vote will be connected to and receive the updated poll statistics through. "
                        summary="I used ReactJS, TailwindCSS, and Socket.io to build the front-end. The backend was created using NodeJS, ExpressJS, Nodemailer, MongoDB, and Socket.io."
                        gitlink="https://github.com/ckade15/easy-polls"
                    />
                </section>
            </main>
        )
    }
    function tabPortContent(){
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <p className='text-4xl font-mono mt-10 font-bold text-blue-800 ' id='h'>My Work</p>
                    <Project width={props.width} name="FIDE Chess Ranking Website" 
                    caption={"Full-stack web application that allows you to view the top 100 chess players in the standard, rapid, and blitz time control."} 
                    summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, and MongoDB for the backend. I wrote bots in Python to webscrape the rankings and upload them to the database through the API." 
                    link="https://fiderankings.kadewebsolutions.com"
                    gitlink="https://www.github.com/ckade15/chess"
                    />
                    <Project width={props.width} name="Notes Web Application" 
                    caption={"Full-stack web application that allows you to create, view, and delete notes. Features a login system with email authentication using nodemailer upon account creation."}
                    summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, jsonwebtoken, nodemailer, and MongoDB for the backend. An email is sent to the user after an account is created with a link to confirm your account. Once confirmed, the user can add, update, or delete their personal notes."
                    gitlink="https://github.com/ckade15/notes-app" />
                    <Project width={props.width} name="Easy-Polls Website" 
                        caption="Full-stack web application that allows users to create, manage, and view polls in real-time. Features a full login system along with authentication. When a poll is created that poll will have a joinable socket that those who vote will be connected to and receive the updated poll statistics through. "
                        summary="I used ReactJS, TailwindCSS, and Socket.io to build the front-end. The backend was created using NodeJS, ExpressJS, Nodemailer, MongoDB, and Socket.io."
                        gitlink="https://github.com/ckade15/easy-polls"
                    />
                </section>
            </main>
        )
    }
    function desktopPortContent(){
        return (
            <main className="w-full min-h-screen bg-blue-200">
                <section className='flex flex-col place-items-center'>
                    <p className='text-4xl font-mono mt-10 font-bold text-blue-800 ' id='h'>My Work</p>
                    <Project width={props.width} name="FIDE Chess Ranking Website" 
                    caption={"Full-stack web application that allows users to view the top 100 chess players in the standard, rapid, and blitz time control."} 
                    summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, and MongoDB for the backend. I wrote bots in Python to webscrape the rankings and upload them to the database through the API." 
                    link="https://fiderankings.kadewebsolutions.com"
                    gitlink="https://www.github.com/ckade15/chess"
                    />
                    <Project width={props.width} name="Notes Web Application" 
                        caption={"Full-stack web application that allows users to create, view, and delete notes. Features a login system with email authentication using nodemailer upon account creation."}
                        summary="I used ReactJS and TailwindCSS to create the frontend. I used NodeJS, ExpressJS, jsonwebtoken, nodemailer, and MongoDB for the backend. An email is sent to the user after an account is created with a link to confirm your account. Once confirmed, the user can add, update, or delete their personal notes."
                        gitlink="https://github.com/ckade15/notes-app" />
                    <Project width={props.width} name="Easy-Polls Website" 
                        caption="Full-stack web application that allows users to create, manage, and view polls in real-time. Features a full login system along with authentication. When a poll is created that poll will have a joinable socket that those who vote will be connected to and receive the updated poll statistics through. "
                        summary="I used ReactJS, TailwindCSS, and Socket.io to build the front-end. The backend was created using NodeJS, ExpressJS, Nodemailer, MongoDB, and Socket.io."
                        gitlink="https://github.com/ckade15/easy-polls"
                    />
                </section>
            </main>
        )
    }
}

export default PortfolioContent;
